<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <UserGroupModalForm
            :modalData="modalData"
            :statuses="statuses"
            :permissions="permissions"
            @closeModalForm="handleCloseModalForm"
            @saveModalForm="handleSaveModalForm"
          ></UserGroupModalForm>
        </v-col>
      </v-row>
      <AlertComponent
        :alertShow="alert.show"
        :alertText="alert.text"
        @closeAlert="alert.show = false"
      ></AlertComponent>
      <v-data-table
        :headers="headers"
        :items="userGroupCollection"
        :search="search"
        :sort-by="['name']"
        @click:row="handleClickItem"
        :footer-props="{
          'items-per-page-options': [100],
        }"
      >
        <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.system.user[item.status].value
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            color="primary"
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          $t("TABLE.are_you_sure_delete")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDelete">{{
            $t("TABLE.cancel")
          }}</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">{{
            $t("TABLE.ok")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import UserGroupModalForm, { initialFormData } from "./UserGroupModalForm";
import AlertComponent from "@/view/components/AlertComponent";
import i18nService from "@/core/services/i18n.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_USER_GROUPS,
  USER_GROUP_COLLECTION,
} from "@/core/services/store/userGroup.module.js";

export default {
  name: "UserGroups",
  components: { UserGroupModalForm, AlertComponent },
  data() {
    return {
      cardTitle: this.$t("FORMS.userGroups"),
      appLocale: i18nService.getActiveLanguage(),
      users: [],
      search: "",
      permissions: [],
      routePath: "settings/userGroups",
      headers: [
        {
          text: this.$t("TABLE.name"),
          value: "groupName",
        },
        { text: this.$t("TABLE.status"), value: "status" },
        { text: this.$t("TABLE.actions"), value: "actions", sortable: false },
      ],
      alert: {
        show: false,
        text: this.$t("TABLE.cannot_delete"),
      },
      modalData: {
        dialog: false,
        languages: i18nService.languages,
        selectedLocale: i18nService.languages[0],
        users: [],
        editedIndex: -1,
        editedItem: Object.assign({}, initialFormData),
        defaultItem: Object.assign({}, initialFormData),
      },
      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters([USER_GROUP_COLLECTION, "statuses"]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions([FETCH_USER_GROUPS]),
    getUsers() {
      ApiService.get("user")
        .then(({ data }) => {
          this.modalData.users = data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },
    editItem(item) {
      ApiService.get("userGroup/" + item.id)
        .then(({ data }) => {
          this.modalData.editedIndex =
            this[USER_GROUP_COLLECTION].indexOf(item);

          // this.modalData.editedItem = Object.assign({}, data);
          this.modalData.editedItem = data;

          this.modalData.dialog = true;
          // console.log("this.modalData.editedItem-", this.modalData.editedItem);
          this.modalData.selectedLocale = i18nService.languages.find((item) => {
            return item.lang == i18nService.getActiveLanguage();
          });
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      // this.editedIndex = this.desserts.indexOf(item)
      this.editedIndex = this[USER_GROUP_COLLECTION].indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.users.length > 0) {
        this.alert.show = true;
      } else {
        this.dialogDelete = true;
      }
    },

    deleteItemConfirm() {
      ApiService.delete("userGroup/" + this.editedItem.id)
        .then(({ data }) => {
          console.log(data);
          this.fetchUserGroups();
          this.getUsers();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = -1;
      this.modalData.editedItem = this.modalData.defaultItem;

      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    handleSaveModalForm(model) {
      /**Delete unused translations */
      this.modalData.languages.forEach((item) => {
        let langCode = item.lang;
        if (
          model.translations[langCode] &&
          model.translations[langCode].name == ""
        ) {
          delete model.translations[langCode];
        }
      });

      if (this.modalData.editedIndex > -1) {
        model.status = parseInt(model.status);
        ApiService.put("userGroup/" + model.id, model)
          .then(({ data }) => {
            console.log(data);
            this.fetchUserGroups();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            this.warningAlert.text = error.message;
            this.warningAlert.show = true;
            console.log("Error!: ", error);
          });
      } else {
        //create model
        model.status = parseInt(model.status);

        ApiService.post("userGroup", model)
          .then(({ data }) => {
            console.log(data);
            this.fetchUserGroups();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      }
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.editItem(item);
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "system.userGroup." + action
      );
    },
    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("userGroup") > -1
        );
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "userGroups" }]);
    this.fetchUserGroups();
    this.getUsers();
    this.setPermissions();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
